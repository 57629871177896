@layer reset, variables, page;

@layer variables {
  :root {
    accent-color: var(--brand-color-primary);
    --gutter: var(--brand-size-component-gutter);
  }
}

@layer page {
  html, body {
    height: 100%;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  body {
    background-color: rgb(var(--brand-color-background));
    color: rgb(var(--brand-color-text));
    font-size: var(--brand-size-text);

    display: grid;
    align-content: stretch;
    grid-template-columns: 25% 75%;
    grid-template-rows: 64px auto 64px;
    grid-template-areas:
      "header header"
      "content content"
      "footer footer";
  }
  main {
    grid-area: content;
    margin: 0;
    padding: 0;
    overflow-y: auto;
  }
  seft-header {
    grid-area: header;
  }
  seft-footer {
    grid-area: footer;
  }
}
